import request from "@/utils/request";

export function login(data: any) {
  return request({
    url: "/sysUser/login",
    method: "post",
    data,
  });
}
// 重置密码
export function resetPassword(data: any) {
  return request({
    url: "/sysUser/updatePass",
    method: "post",
    data,
  });
}
// 退出登录
export function loginOut(data: any) {
  return request({
    url: "/sysUser/logout",
    method: "post",
    data,
  });
}
// 包配置列表
export function sysConfigList(data: any) {
  return request({
    url: "/sysData/configList",
    method: "get",
    params: data,
  });
}
// 数据列表
export function sysDailyList(data: any) {
  return request({
    url: "/sysData/dailyList",
    method: "get",
    params: data,
  });
}
// 投放数据列表
export function sysNovelUserOrderList(data: any) {
  return request({
    url: "/sysNovelUserOrder/queryList",
    method: "get",
    params: data,
  });
}
// 投放数据修改
export function sysNovelUserOrderSave(data: any) {
  return request({
    url: "/sysNovelUserOrder/save",
    method: "post",
    data,
  });
}
// 应用列表
export function sysNovelConfigList(data: any) {
  return request({
    url: "/sysNovelConfig/queryListAll",
    method: "get",
    params: data,
  });
}
// 小说数据
export function sysNovelUserOrderDataList(data: any) {
  return request({
    url: "/sysNovelUserOrder/data/queryList",
    method: "get",
    params: data,
  });
}
// 首页数据
export function sysNovelUserOrderHome(data: any) {
  return request({
    url: "/sysNovelUserOrder/home/queryList",
    method: "get",
    params: data,
  });
}
// 首页数据列表
export function sysNovelUserOrderHomeListBottom(data: any) {
  return request({
    url: "/sysNovelUserOrder/home/queryListBottom",
    method: "get",
    params: data,
  });
}
// 保存数据
export function sysNovelConfigSave(data: any) {
  return request({
    url: "/sysNovelConfig/save",
    method: "post",
    data,
  });
}
// 删除数据
export function sysNovelConfigDel(data: any) {
  return request({
    url: "/sysNovelConfig/del",
    method: "post",
    data,
  });
}
// 获取facebook business账户数据
export function businessPage(data: any) {
  return request({
    url: "/oauth/facebook/business/Page",
    method: "get",
    params: data,
  });
}
// 获取统计数据
export function businessCount(data: any) {
  return request({
    url: "/oauth/facebook/business/count",
    method: "get",
    params: data,
  });
}
// 获取订单汇总列表
export function ordersList(data: any) {
  return request({
    url: "/sysData/area/orders",
    method: "get",
    params: data,
  });
}
// 获取新用户数据列表
export function newUserList(data: any) {
  return request({
    url: "/sysData/area/newUserList",
    method: "get",
    params: data,
  });
}
//今日阅读数据
export function historyToDay(data: any) {
  return request({
    url: "/sysData/area/historyToDay",
    method: "get",
    params: data,
  });
}
//昨日阅读数据
export function historyYesToDay(data: any) {
  return request({
    url: "/sysData/area/historyYesToDay",
    method: "get",
    params: data,
  });
}
// 订单监控
export function orderInTime(data: any) {
  return request({
    url: "/sysData/area/orderInTime",
    method: "get",
    params: data,
  });
}
// 订单监控列表
export function orderInTimeList(data: any) {
  return request({
    url: "/sysData/area/orderInTimeList",
    method: "get",
    params: data,
  });
}
// 订阅监控列表
export function orderSubCancelList(data: any) {
  return request({
    url: "/sysData/area/orderSubCancelList",
    method: "get",
    params: data,
  });
}
// 订阅监控轮训
export function orderSubCancel(data: any) {
  return request({
    url: "/sysData/area/orderSubCancel",
    method: "get",
    params: data,
  });
}

// 退款监控列表
export function orderRefundList(data: any) {
  return request({
    url: "/sysData/area/orderRefundList",
    method: "get",
    params: data,
  });
}
// 退款监控轮训
export function orderRefund(data: any) {
  return request({
    url: "/sysData/area/orderRefund",
    method: "get",
    params: data,
  });
}
// 广告列表数据
export function admDateList(data: any) {
  return request({
    url: "/sysData/admDateList",
    method: "get",
    params: data,
  });
}
// 广告配置列表
export function configAdmList(data: any) {
  return request({
    url: "/sysData/configAdmList",
    method: "get",
    params: data,
  });
}
// 广告配置列表详情
export function admDateListAdUnit(data: any) {
  return request({
    url: "/sysData/admDateListAdUnit",
    method: "get",
    params: data,
  });
}
